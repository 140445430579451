import { css, keyframes } from "@emotion/react"
import ImgBg from '../../images/LP/bg-header.png'

export const header = css`
  background-color: #FFB800;
  font-family: 'Lato', sans-serif;
  padding-top: 120px;
  @media (max-width: 480px) {
    padding-top: 148px;
    padding-bottom: 62px;
  }
`

export const logo = css`
  background-color: #5315FF;
  font-family: 'Montserrat';
  padding-top: 34px;
  @media (max-width: 480px) {
    padding-left: 18px;
  }
`

export const header2 = css`
  /* background-color: #5315FF; */
  background-image: url(${ImgBg});
  font-family: 'Montserrat';
  padding: 60px 0px;
  form {
    width: 100%;
      max-width: 435px;
    p {
      margin-bottom: 8px;
    }
    input {
      font-family: 'Montserrat';
      height: 64px;
      border-radius: 10px;
      border: 1.5px solid #4117DD;
      background: #FFF;
      /* padding-left: 8px; */
      outline: none;
      color: #2D3436;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      max-width: 435px;
      &::placeholder {
        color: #2D3436;
      }
    }
    button {
      font-family: 'Montserrat';
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1.5px solid #FB839D;
      background: #FA5276;
      cursor: pointer;
      color: #FFF;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      transition: 0.3s;
      width: 100%;
      max-width: 435px;
    }
  }
  @media (max-width: 480px) {
    padding-top: 0px;
  }
`

export const Sucessocont = css`
  background-color: #5315FF;
  /* background-image: url(${ImgBg}); */
  font-family: 'Montserrat';
  padding: 60px 0px;
  padding-bottom: 0px;
  h3 {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 16px;
  }
  h4 {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }
  a {
      font-family: 'Montserrat';
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1.5px solid #FB839D;
      background: #FA5276;
      cursor: pointer;
      color: #FFF;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      transition: 0.3s;
      width: 100%;
      max-width: 320px;
    }
  }
  @media (max-width: 480px) {
    padding-top: 0px;
  }
`

export const contatoLp = css`
  background-color: #FA5276;
  font-family: 'Montserrat';
  padding: 60px 0px;
  .form-go {
    form {
    p {
      margin-bottom: 8px;
    }
    input {
      font-family: 'Montserrat';
      width: 100%;
      height: 64px;
      border-radius: 10px;
      border: none;
      background: #FFF;
      width: 100%;
      max-width: 435px;
      outline: none;
      color: #2D3436;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      
      &::placeholder {
        color: #2D3436;
      }
    }
    button {
      font-family: 'Montserrat';
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1.5px solid #41E2A9;
      background: #41E2A9;
      cursor: pointer;
      color: #2D3436;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      transition: 0.3s;
      margin-top: 24px;
      width: 100%;
      max-width: 435px;
    }
  }
  }
  h3 {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
    width: 100%;
    max-width: 435px;
    span {
      color: #FFC008;
    }
  }
  h4 {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 16px;
  }
  /* @media (max-width: 480px) {
    padding-bottom: 62px;
  } */
`

export const CVALp = css`
  background-color: #FFA100;
  font-family: 'Montserrat';
  padding: 60px 0px;
  scroll-margin-top: 120px;
  h5 {
    color: #2D3436;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.24px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h4 {
    color: #2D3436;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
  }
  /* @media (max-width: 480px) {
    padding-bottom: 62px;
  } */
`

export const EnsinoPersonalizadoLp = css`
  background-color: #FFF;
  font-family: 'Montserrat';
  padding-top: 60px;
  padding-bottom: 160px;
  scroll-margin-top: 120px;
  h5 {
    color: #2D3436;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.24px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h3 {
    width: 100%;
    max-width: 505px;
    color: #2D3436;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    span {
      color: #5315FF;
    }
  }
  h4 {
    width: 100%;
    max-width: 435px;
    color: #2D3436;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    span {
      font-weight: 700;
    } 
  }
  @media (max-width: 480px) {
    padding-bottom: 162px;
  }
`

export const BasePersonalizada = css`
  background-color: #FFF;
  font-family: 'Montserrat';
  padding-top: 60px;
  scroll-margin-top: 120px;
  h5 {
    color: #2D3436;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.24px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h3 {
    width: 100%;
    max-width: 505px;
    color: #2D3436;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    span {
      color: #5315FF;
    }
  }
  h4 {
    width: 100%;
    max-width: 435px;
    color: #2D3436;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    span {
      font-weight: 700;
    } 
  }
  /* @media (max-width: 480px) {
    padding-bottom: 62px;
  } */
`

export const BilinguismoLp = css`
  background-color: #41E2A9;
  font-family: 'Montserrat';
  padding-top: 60px;
  padding-bottom: 60px;
  scroll-margin-top: 120px;
  h5 {
    color: #2D3436;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.24px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  h3 {
    width: 100%;
    max-width: 505px;
    color: #2D3436;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 48px;
    span {
      color: #5315FF;
    }
  }
  h4 {
    width: 100%;
    max-width: 435px;
    color: #2D3436;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    span {
      font-weight: 700;
    } 
  }
  /* @media (max-width: 480px) {
    padding-bottom: 62px;
  } */
`

export const domineARedacao = css`
  background-color: #E8E8DB;
  font-family: 'Source Sans Pro', sans-serif;
  padding-top: 80px;
  @media (max-width: 480px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 0px;
	padding: 0px;
  /* flex: 1;
	margin: 2px;
	padding: 10px; */
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    padding: 18px !important;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 18px !important;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  h1 {
    font-weight: 900;
    font-size: 72px;
    line-height: 62px;
    color: #5416FF;
    display: flex;
    align-items: center;
    max-width: 470px;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    font-family: 'Lato', sans-serif;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    max-width: 470px;
    width: 100%;
    margin: 0;
    margin-bottom: 36px;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #262626;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 48px;
      line-height: 45px;
    }
    p {
      margin-bottom: 0px;
    }
    img {
      width: 100%;
    }
  }
`

export const contentPosition = css`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 60px;
  @media (max-width: 480px) {
    padding: 8px;
    margin-left: 0px;
    a {
      margin-top: 32px;
    }
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`

// export const desktop = css`
//   display: flex;
//   @media (max-width: 480px) {
//     display: none;
//   }
// `

export const cloud = css`
  width: 200px; height: 60px;
	background: #fff;
	border-radius: 200px;
	position: relative; 
  &:before, &:after {
    content: '';
    position: absolute; 
    background: #fff;
    width: 100px; height: 80px;
    position: absolute; top: -15px; left: 10px;    
    border-radius: 100px;    
    transform: rotate(30deg);
  }
  &:after {
    width: 120px; height: 120px;
	  top: -55px; left: auto; right: 15px;
  }
`

const moveclouds = keyframes`
  0% {margin-left: 1000px;}
	100% {margin-left: -1000px;}
`

export const cloudItem = css`
  animation: ${moveclouds} 15s linear infinite;
`

